/**
 * Module representing a collection of businesscase-related functions.
 * @module GlosaaryAPI
 * @version 0.0.1
 */

import apiClient from "./common/apiClient";
import errorHandler from "./common/errorHandler";

export default {
  /**
   * get all definition elated to the request.
   * @async
   * @function getSimilaritySearch
   * @param {object} request- The ID of the businessCase project to retrieve.
   * @returns {Promise} A promise that resolves to the project data.
   * @throws {Error} If an error occurs during the API request.
   */
  async getSimilaritySearch(request) {
    try {
      const response = await apiClient.post(`/glossary/similarity`, request);
      return response.data.result;
    } catch (error) {
      return errorHandler(error);
    }
  },

  /**
   * get list of keyword.
   * @async
   * @function getDefinitionByKeyword
   * @param {wordResearch} word - The updated project object.
   * @returns {Promise} A promise that resolves to the updated project data.
   * @throws {Error} If an error occurs during the API request.
   */

  async getKeywordList() {
    try {
      const response = await apiClient.get('/glossary/list/');
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  /**
   * getDefinition by keyword.
   * @async
   * @function getDefinitionByKeyword
   * @param {wordResearch} word - The updated project object.
   * @returns {Promise} A promise that resolves to the updated project data.
   * @throws {Error} If an error occurs during the API request.
   */
  async getDefinitionByKeyword(word) {
    try {
      const response = await apiClient.get(`/glossary/keyword/${word}`);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
}